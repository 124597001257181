/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/alpinejs@2.5.0/dist/alpine.min.js
 * - /npm/interactjs@1.9.20/dist/interact.min.js
 * - /npm/prismjs@1.20.0/prism.min.js
 * - /npm/prismjs@1.20.0/plugins/normalize-whitespace/prism-normalize-whitespace.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
